export const ADD_MAPPING = 'ADD_MAPPING';
export const REMOVE_MAPPING = 'REMOVE_MAPPING';
export const ADD = 'ADD';
export const REMOVE = 'REMOVE';

export const COMPARE_MODE_ON = 'COMPARE_MODE_ON';
export const COMPARE_MODE_OFF = 'COMPARE_MODE_OFF';

export const DO_COMPARE = 'DO_COMPARE';

export const DO_CLEAR = 'DO_CLEAR';
